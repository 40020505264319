/*** COLORS ***/
$primary-color: #3a3f51;
$secondary-color: #646b85;
$accent-color: #e34a2f;

$gray: #6f7486;
$gray-ligth: #e2e8f0;
$gray-ultra-ligth: #f5f7fa;
$gray-ultra-ligth-alt: #f5f5f5;


/*** BUTTONS ***/
$button-primary-color: #3a3f51;
$button-secondary-color: #678baf;
$button-success-color: #6ea158;
$button-warn-color: #f4ab1e;
$button-danger-color: #e34a2f;
$button-basic-color: #7f8393;


/*** HEADER ***/
$header-height: 45px;
$header-bg-color: $accent-color;
$header-text-color: white;


/*** SIDEBAR ***/
$sidebar-width: 90px;
$sidebar-bg-color: $primary-color;
$sidebar-text-color: white;
