@use "variables" as *;

/* Importing Bootstrap SCSS file. */
@use 'bootstrap/scss/bootstrap' as *;


/* Importing Bootstrap SCSS file. */
$primary: $primary-color;
@use 'bootstrap/scss/bootstrap' as *;
@import 'bootstrap-icons/font/bootstrap-icons.css';


/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;

    // checkbox styles
    --mdc-checkbox-selected-icon-color: var(--primary);
    --mdc-checkbox-selected-focus-icon-color: var(--primary);
    --mdc-checkbox-selected-hover-icon-color: var(--primary);
    --mdc-checkbox-selected-pressed-icon-color: var(--primary);
    --mdc-checkbox-selected-focus-state-layer-color: var(--primary);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--primary);
}

.row {
    --bs-gutter-x: 0.8rem;
    --bs-gutter-y: 0.8rem;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #F5F7FA;
}

:root {
    --primary: #3A3F51;
    --chip-entities: rgba(100, 107, 133, 0.75) !important;
    --secondary: #646B85;
    --gray: #6f7486;
    --minor-highlight: #7F8393;
    --assistant: #678BAF;
    --chip-text: rgba(35, 183, 229, 0.7) !important;
    --download: #23b7e5;
    --primary-system: #3F51B5;
    --purple-highlight: #6558B4;
    --success: #6EA158;
    --action: #F4AB1E;
    --delete: #E34A2F;
    --gray-ligth: #e2e8f0;
    --gray-ultra-ligth: #f5f7fa;
}

table {
    tr {
        height: 25px !important;
    }

    td {
        font-size: smaller !important;
    }
}

h4 {
    font-size: larger !important;
}

p {
    font-size: smaller !important;
}

mat-checkbox {
    .mdc-form-field {
        height: 10px !important;
    }

    .mdc-label {
        font-size: smaller !important;
    }
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
    border-color: lightgray !important;
    background-color: lightgray !important;
}

.table-information {
    th {
        font-weight: normal;
        padding: 0 !important;
    }

    td {
        font-weight: 500;
        padding: 0 !important;
        font-size: smaller !important;
    }

    tr {
        height: 0 !important;
    }
}

h3 {
    font-weight: normal;
    margin-bottom: 0;
}

button {
    font-weight: normal !important;
    letter-spacing: 0 !important;
}

/***** ANGULAR MATERIAL OVERRIDE *****/
:root {
    --mdc-typography-button-letter-spacing: 0.02em;
}

.mat-mdc-card {
    --mdc-elevated-card-container-shape: 0px !important;
    --mdc-elevated-card-container-elevation: 5px 5px 10px -1px rgba(0, 0, 0, 0.1) !important;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
    box-shadow: none !important;
}

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
    min-width: 1rem;
}

.mdc-text-field {
    border-radius: 0 !important;
}

.mat-form-field-invalid {
    .mat-mdc-select {
        color: red;
    }
}

/***** TABELAS *****/
.line-background {
    background-color: #eef6ff !important;
}

.td-actions {
    button {
        margin-left: 5px;
    }
}

/***** END *****/

.icon-actions {
    cursor: pointer !important;
    transform: scale(0.8);
}

.icon-actions.primary {
    color: var(--primary) !important;
}

.icon-actions.secondary {
    color: var(--secondary) !important;
}

.icon-actions.delete {
    color: var(--delete) !important;
}

.icon-actions.minor-highlight {
    color: var(--minor-highlight) !important;
}

.icon-actions.action {
    color: var(--action) !important;
}

.icon-actions.success {
    color: var(--success) !important;
}

.icon-actions.assistant {
    color: var(--assistant) !important;
}

.button-actions-delete, .button-actions-primary, .button-actions-download, .button-actions-secondary,
.button-actions-action, .button-actions-purple-highlight, .button-actions-success, .button-actions-assistant,
.button-actions-minor-highlight {
    color: white !important;
}

.button-actions-delete {
    background-color: var(--delete) !important;
}

.button-actions-primary {
    background-color: var(--primary) !important;
}

.button-actions-download {
    background-color: var(--download) !important;
}

.button-actions-secondary {
    background-color: var(--secondary) !important;
}

.button-actions-primary-system {
    background-color: var(--primary-system) !important;
}

.button-actions-action {
    background-color: var(--action) !important;
}

.button-actions-purple-highlight {
    background-color: var(--purple-highlight) !important;
}

.button-actions-success {
    background-color: var(--success) !important;
}

.button-actions-assistant {
    background-color: var(--assistant) !important;
}

.button-actions-minor-highlight {
    background-color: var(--minor-highlight) !important;
}

.button-actions-primary.text, .button-actions-download.text, .button-actions-secondary.text,
.button-actions-action.text, .button-actions-success.text, .button-actions-delete.text, .button-actions-assistant.text,
.button-actions-minor-highlight.text, .button-actions {
    border-radius: 5px !important;
    height: 37px !important;
}

mat-form-field {
    width: 100%;

    mat-label, input, mat-select, span {
        font-size: 11px !important;
    }

    mat-label {
        color: rgba(0, 0, 0, 0.54) !important;
    }
}

textarea {
    max-height: 200px !important;
    min-height: 100px !important;
}

.input-sm-identification {
    .mat-mdc-form-field-input-control {
        width: 200px !important;
    }

    .mdc-text-field--filled {
        padding-right: 26px;
    }
}

.mat-mdc-mini-fab:not(.mdc-fab--extended) {
    height: 27px !important;
    width: 27px !important;
}

td {
    .mat-mdc-mini-fab:not(.mdc-fab--extended) {
        height: 20px !important;
        width: 20px !important;
    }
}

.input-sm, .input-sm-chip {
    --mdc-typography-body1-font-size: 11px;

    .mat-mdc-form-field-icon-suffix {
        display: flex !important;
        margin-bottom: 100px;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        margin: 0;
        padding: 0.5rem;
        transform: scale(1);
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding: 6px 8px 100px;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding: 6px 8px 100px;
    }

    .mat-mdc-form-field-icon-suffix {
        margin-bottom: 100px;
    }

    .mdc-floating-label {
        padding: 6px 0 11px;
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: 0;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-top: 11px;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 11px;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 20px;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-70%) scale(0.75);
    }

    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
        button {
            transform: scale(0.8);
            transform-origin: top;
        }
    }
}

.input-sm {
    .mdc-text-field--filled {
        height: 35px;
    }
}

.user-chip {
    background-color: var(--chip-text) !important;

    span.ng-star-inserted {
        white-space: normal;
        user-select: text;
    }
}

.input-xs {
    --mdc-typography-body1-font-size: 13px;

    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding: 6px 8px 20px;
    }

    .mdc-floating-label {
        padding: 0 0 20px;
    }

    .mat-mdc-form-field-infix {
        min-height: 35px;
    }

    .mat-mdc-form-field-subscript-wrapper {
        height: 0;
    }

    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-top: 14px;
        padding-bottom: 5px;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
        padding-top: 6px;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
        transform: translateY(-76%) scale(0.75);
    }

    //CHIPS
    mat-chip-row {
        height: auto;
        width: auto;

        span.ng-star-inserted {
            white-space: normal;
            user-select: text;
        }
    }

    .mat-mdc-chip-action-label {
        color: white !important;
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
        --mdc-chip-container-height: 24px;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-top: 0;
        margin-bottom: 2px;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-left: 5px;
        margin-right: 0;
    }
}

.mdc-fab--mini {
    width: 32px !important;
    height: 32px !important;

    .mat-icon {
        font-size: 20px !important;
        line-height: 24px !important;
    }

    &.mat-primary {
        background-color: $primary-color;
    }

    &.micro-fab {
        width: 28px !important;
        height: 28px !important;
    }
}

td {
    .mdc-fab--mini {
        .mat-icon {
            font-size: 15px !important;
            margin-top: 2px;
            line-height: 22px !important;
        }
    }
}

.fa-brands {
    font-size: 20px !important;
}

.iconCbrEditors {
    width: 20px !important;
    height: 20px !important;
}

.background-white {
    background-color: white
}

.background-light-blue {
    background-color: var(--gray-ligth);
}

.background-gray {
    background-color: $gray;
}

.background-gray-ligth {
    background-color: var(--gray-ligth);
}

.background-gray-ultra-ligth {
    background-color: $gray-ultra-ligth;
}

.background-gray-ultra-ligth-alt {
    background-color: $gray-ultra-ligth-alt;
}

.input-white {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: white;
    }

    &.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
        opacity: .02 !important;
    }

    &.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
        opacity: .02 !important;
    }
}

.icon-remove {
    font-size: 13px;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
    background-color: var(--delete) !important;
    color: #fff;
}

.mat-datepicker-toggle-active.mat-accent {
    color: var(--secondary) !important;
}

.mat-accent {
    --mat-option-selected-state-label-text-color: var(--secondary) !important;
}

.hide-input {
    display: none;
}

.tr-style {
    cursor: pointer;
}

.tr-style:hover {
    background-color: #F5F7FA !important;
}

mat-chip-row {
    border-radius: 5px !important;
    background-color: var(--chip-entities) !important;

    span {
        color: white;
    }

    button mat-icon {
        color: white;
    }
}

.table-information {
    th {
        font-weight: normal;
        padding: 0 !important;
        font-size: smaller !important;
    }

    td {
        font-weight: 500;
        padding: 0 !important;
    }

    tr {
        height: 0 !important;
    }
}

.mat-datepicker-content {
    height: 25em;
}

.background-title {
    background-color: white;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.background-filter {
    background-color: var(--gray-ligth);
    padding: 0.5rem 1.5rem;
}

.d-contents {
    display: contents;
}

.fs-7 {
    font-size: 12px;
}

.remove {
    transform: rotate(45deg);
}

.hide-touch-target .mat-mdc-button-touch-target {
    display: none !important;
}

.badge-custom {
    margin-top: 10px;
}
